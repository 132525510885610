import "../styles/pages/page-casestudies.scss";

import React from "react";
import Layout from "components/Layout";
import Seo from "components/Seo";
import { graphql, Link } from "gatsby";

import SubpageHeader from "components/SubpageHeader";
import CaseStudyTile from "components/CaseStudyTile/CaseStudyTile";

const CaseStudies = ({ pageContext, data }) => {
  const caseStudyPage = pageContext.pageContent;
  const caseStudies = data.caseStudy.edges;
  return (
    <Layout>
      <Seo
        title={
          pageContext.seo.title ? pageContext.seo.title : pageContext.title
        }
        description={pageContext.seo?.description}
      />
      <SubpageHeader
        title={pageContext.title}
        image={require("assets/images/news-img-bg.png").default}
      />

      <section className="section-cs">
        <div className="container">
          <div className="row section-cs__row">
            {caseStudies?.map((edges, index) => {
              const item = edges.node;

              return (
                <div className="col-lg-4 col-md-6 section-cs__tile" key={index}>
                  <CaseStudyTile
                    title={item.title}
                    desc={item.caseStudiesShortDesc.shortDescription}
                    thumbnail={item.featuredImage?.node.sourceUrl}
                    link={item.uri}
                    category={item.caseStudyCategories.nodes[0].name}
                    color={
                      item.caseStudyCategories.nodes[0].caseStudyCategoryColor
                        .categoryColor
                    }
                    isActive={true}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query {
    caseStudy: allWpCaseStudy(limit: 4) {
      edges {
        node {
          title
          slug
          uri
          seo {
            metaDesc
            title
          }
          caseStudiesShortDesc {
            shortDescription
          }
          caseStudyCategories {
            nodes {
              name
              caseStudyCategoryColor {
                categoryColor
              }
            }
          }
          content
          featuredImage {
            node {
              sourceUrl
            }
          }
        }
      }
    }
  }
`;

export default CaseStudies;
